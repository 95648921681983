<template>
    <div class="mx-3">

        <v-toolbar color="primary" dense dark>
            <v-toolbar-title>
                Listado de anuncios
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click = "list">
                <v-icon>mdi-sync</v-icon>
            </v-btn>
            <v-btn icon @click = "nuevo">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click = "$router.go(-1)">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
        </v-toolbar>

        <v-data-table
            elevation="2"
            :headers="headers"
            :items="datos"
            disable-sort
            :footer-props="{
                'items-per-page-options': [10, 20, 30, 40, 50]
            }"
            item-key="id"
            :loading="loadingDatosTodos"

            :server-items-length='totalRegistros'
            :options.sync="optionsTable"
        >
            <template v-slot:[`item.estados`]="{ item }">
                <div>
                    <v-icon v-if="item.activo" color="green" @click="setActiva(item.id, false)">mdi-check-bold</v-icon>
                    <v-icon v-if="!item.activo" @click="setActiva(item.id, true)">mdi-check</v-icon>

                    <v-icon v-if="item.principal" color="orange" @click="setPrincipal(item.id, false)">mdi-asterisk-circle-outline</v-icon>
                    <v-icon v-if="!item.principal" @click="setPrincipal(item.id, true)">mdi-asterisk</v-icon>
                </div>
            </template>

            <template v-slot:[`item.dateCreated`]="{ item }">
                {{moment(item.dateCreated).format('DD-MM-YYYY HH:mm')}}
            </template>

            <template v-slot:[`item.acciones`]="{ item }">
                <v-icon @click="cargaMensaje(item)">mdi-magnify</v-icon>
            </template>

        </v-data-table>


        <dialog-comunicado
            :dialogModal.sync="dialogModal2"
            :esteMensaje = "esteMensaje"
            @guardado = "list"
        />
    </div>
</template>

<script>
export default {
    mounted(){
        this.list()
    },
    components:{
        'dialog-comunicado':()=>import('@/views/admin/bb/ComunicadoModal')
    },
    data:()=>({
        headers:[
            {text:'', value:'estados', align:'center', width: '10%' },
            {text:'Fecha', value:'dateCreated', align:'center', width: '20%'},
            {text:'Título', value:'titulo'},
            {text:'', value:'acciones', align:'center', width: '5%'},
        ],
        datos:[],
        loadingDatosTodos:false,
        totalRegistros:0,
        optionsTable:{},
        paramsBusqueda:{},

        dialogModal2:false,
        esteMensaje:{},

        dat:{},

    }),

    methods:{
        async guardaEntrada(){
            this.loadingDatosTodos = true
            try{
                let req = await this.$http({
                    method:'POST',
                    url: '/admin/guardaMensaje',
                    params: this.dat,
                })
                this.loadingDatosTodos = false
                if (req.data.estado){
                    this.list()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loadingDatosTodos = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        cargaMensaje(itm){
            this.esteMensaje = itm
            this.dialogModal2 = true
        },
        async list(){
            this.loadingDatosTodos = true
            try{
                let req = await this.$http({
                    method:'POST',
                    url: '/admin/listMensajes',
                    params: this.paramsBusqueda,
                })
                this.loadingDatosTodos = false
                this.datos = req.data.root
                this.totalRegistros = req.data.total
            } catch(err){
                this.loadingDatosTodos = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        nuevo(){
            this.esteMensaje={}
            this.dialogModal2 = true
        },
        setActiva(id, st){
            this.dat.id = id
            this.dat.activo = st
            this.guardaEntrada()

        },
        setPrincipal(id, st){
            this.dat.id = id
            this.dat.principal = st
            this.guardaEntrada()

        }

    },
}
</script>

<style>

</style>